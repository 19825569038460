import request from "@/utils/request";

export function fetchArticleList(params) {
  return request({
    url: "/v1/article/",
    method: "get",
    params: params,
  });
}
// 推荐活动
export function getRecomment(params) {
  return request({
    url: "/v1/article/recomment",
    method: "get",
    params: params,
  });
}
// 活动报名
export function applyEvent(id) {
  return request({
    url: "/v1/article/event/apply/" + id,
    method: "post",
  });
}
//转发
export function forwarding(id) {
  return request({
    url: "/v1/article/forwarding/" + id,
    method: "post",
  });
}
// 推荐博客用户列表
export function getUserList(params) {
  return request({
    url: "/v1/user/recommend/user",
    method: "get",
    params: params,
  });
}
// 年份 带统计
export function getYears(params) {
  return request({
    url: "/v1/article/years",
    method: "get",
    params: params,
  });
}
// 月份
export function getMonths(params) {
  return request({
    url: "/v1/article/months",
    method: "get",
    params: params,
  });
}
// blog home topList
export function getTopList(params) {
  return request({
    url: "/v1/blogs",
    method: "get",
    params: params,
  });
}
// 根据uid获取用户信息
export function getUserById(params) {
  return request({
    url: "/v1/user/selectUserInfoByUid",
    method: "get",
    params: params,
  });
}
// 获取文章状态
export function getBlogStatus(params) {
  return request({
    url: "/v1/article/selectMyArticle/status",
    method: "get",
    params: params,
  });
}
// 查询我的文章
export function getMyBlogList(params) {
  return request({
    url: "/v1/article/selectMyArticle",
    method: "get",
    params: params,
  });
}
// 获取关注列表
export function getFollowedList(params) {
  return request({
    url: "/v1/followed/",
    method: "get",
    params: params,
  });
}
// 新闻banner
export function getBanner(params) {
  return request({
    url: "/system/webConfig/dict/data",
    method: "get",
    params: params,
  });
}
export function getDictList(params) {
  return request({
    url: "/system/webConfig/dict",
    method: "get",
    params: params,
  });
}
export function getSysConfigList(params) {
  return request({
    url: "/system/webConfig/sysconfig",
    method: "get",
    params: params,
  });
}
// history
export function getHistory(params) {
  return request({
    url: "/v1/article/history",
    method: "get",
    params: params,
  });
}
// 历史推荐
export function getHistoryRec(params) {
  return request({
    url: "/v1/article/history/recommend",
    method: "get",
    params: params,
  });
}
export function getMyArticle(params) {
  return request({
    url: "/v1/article/selectMyArticle",
    method: "get",
    params: params,
  });
}
export function deleteMyArticle(id) {
  return request({
    url: "/v1/article/",
    method: "delete",
    params: {
      id: id,
    },
  });
}
export function getMyArticleInfo(id) {
  return request({
    url: "/v1/article/selectMyArticleInfo",
    method: "get",
    params: {
      id: id,
    },
  });
}
export function insertArticle(data) {
  return request({
    url: "/v1/article/",
    method: "post",
    data,
  });
}
export function searchArticle(params) {
  return request({
    url: "/v1/article/search",
    method: "get",
    params: params,
  });
}

export function articleInfo(id, typec) {
  return request({
    url: "/v1/article/info",
    method: "get",
    params: {
      id: id,
      typec,
    },
  });
}
export function selectUserInfoByArticleId(id) {
  return request({
    url: "/v1/article/selectUserInfoByArticleId",
    method: "get",
    params: {
      id: id,
    },
  });
}
export function checkCode(code) {
  return request({
    url: "/v1/article/checkCode",
    method: "get",
    params: {
      code: code,
    },
  });
}
export function articleLike(id) {
  return request({
    url: "/v1/article/like",
    method: "get",
    params: {
      articleId: id,
    },
  });
}
export function archive() {
  return request({
    url: "/v1/article/archive",
    method: "get",
  });
}

export function fetchTagList(data) {
  return request({
    url: "/v1/tag/",
    method: "get",
    params: data,
  });
}
// res 第二个分类
export function fetchTagList2(data) {
  return request({
    url: "/v1/category/",
    method: "get",
    params: data,
  });
}

export function getMedal(medalId) {
  return request({
    url: "/v1/getMedal/" + medalId,
    method: "get",
  });
}

export function featchHomeData() {
  return request({
    url: "/v1/",
    method: "get",
  });
}
export function getHot(type) {
  return request({
    url: "/v1/hot",
    method: "get",
    params: {
      type: type,
    },
  });
}
export function getMusic() {
  return request({
    url: "/v1/music",
    method: "get",
  });
}
export function report() {
  return request({
    url: "/v1/report",
    method: "get",
  });
}
export function getWebSiteInfo() {
  return request({
    url: "/v1/webSiteInfo",
    method: "get",
  });
}

export function emailLogin(data) {
  return request({
    url: "/oauth/emailLogin",
    method: "post",
    data,
  });
}
export function emailRegister(data) {
  return request({
    url: "/oauth/emailRegister",
    method: "post",
    data,
  });
}
export function emailBind(data) {
  return request({
    url: "/oauth/email/bind",
    method: "post",
    data,
  });
}
export function emailCode(email, type) {
  return request({
    url: "/oauth/email/code/" + email,
    method: "get",
    params: { type: type },
  });
}
export function emailReset(data) {
  return request({
    url: "/oauth/resetPassword",
    method: "post",
    data,
  });
}
export function logout() {
  return request({
    url: "/logout",
    method: "get",
  });
}

export function openAuthUrl(source) {
  return request({
    url: "/oauth/render/" + source,
    method: "get",
  });
}
export function authCallback(source, params) {
  return request({
    url: "oauth/callback/check/" + source,
    method: "get",
    params,
  });
}
export function wxIsLogin(loginCode) {
  return request({
    url: "/oauth/wechat/is_login",
    method: "get",
    params: {
      loginCode: loginCode,
    },
  });
}
export function getWechatLoginCode() {
  return request({
    url: "/oauth/wechatLoginCode",
    method: "get",
  });
}
export function updateUserInfo(data) {
  return request({
    url: "/v1/user/",
    method: "put",
    data,
  });
}
export function getUserInfo() {
  return request({
    url: "/v1/user/info",
    method: "get",
  });
}
export function getAllUser() {
  return request({
    url: "/v1/user/all",
    method: "get",
  });
}
export function getUserTotal() {
  return request({
    url: "/v1/user/total",
    method: "get",
  });
}
export function selectUserInfoByToken(token) {
  return request({
    url: "/v1/user/selectUserInfoByToken",
    method: "get",
    params: {
      token: token,
    },
  });
}
export function upload(data) {
  return request({
    url: "/file/upload",
    method: "POST",
    headers: { "Content-Type": "multipart/articles-data" },
    data,
  });
}
export function updatePassword(data) {
  return request({
    url: "/system/user/updatePassword",
    method: "post",
    data,
  });
}

export function featchCategory() {
  return request({
    url: "/v1/category/",
    method: "get",
  });
}

export function addFeedback(data) {
  return request({
    url: "/v1/feedback/",
    method: "post",
    data,
  });
}
export function getCollect(params) {
  return request({
    url: "/v1/collect/",
    method: "get",
    params,
  });
}
// 获取浏览记录
export function getUserLog(params) {
  return request({
    url: "/v1/userLog/",
    method: "get",
    params,
  });
}
export function collect(id) {
  return request({
    url: "/v1/collect/collect",
    method: "get",
    params: {
      articleId: id,
    },
  });
}
export function cancelCollect(id) {
  return request({
    url: "/v1/collect/",
    method: "delete",
    params: {
      articleId: id,
    },
  });
}

export function followedUser(userId) {
  return request({
    url: "/v1/followed/insertFollowed",
    method: "post",
    params: {
      userId: userId,
    },
  });
}
export function deleteFollowedUser(userId) {
  return request({
    url: "/v1/followed/deleteFollowed",
    method: "delete",
    params: {
      userId: userId,
    },
  });
}
