<template>
  <header class="header" style="background: #fff">
    <div id="pre" :style="{ width: widthPre + '%' }"></div>

    <nav class="top-box">
      <div class="box1">
        <div class="left small-top">
          <img src="../../imgs/index/logo.png" alt="" class="logo" @contextmenu="handleRightClick($event)" />

          <img class="big-hide menu" @click="menuFlag = !menuFlag" src="../../imgs/index/menu.png" alt="" />
        </div>
        <div class="right">
          <div class="searchBox small-hide" v-show="!noneInput">
            <div class="search_bar search_open">
              <el-input @focus="focus" @blur="blur" type="text" placeholder="Search" v-model="keywords"
                @keyup.enter.native="search" />

              <img @click="search" class="search-img" src="../../imgs/index/top-1.png" alt="" />
              <!-- <p class="search_ico" @click="search">
                Search

                <i class="iconfont icon-search">
                 
                </i>
              </p> -->
            </div>
            <!-- 热搜框 -->
            <div class="hot_search_main" :style="style">
              <a @click="handleArticle(item)" href="javascript:;" v-for="(item, index) in $store.state.hotArticles"
                :key="index">
                <span :style="{ backgroundColor: `${colors[index]}` }">{{ index + 1 }}</span>
                {{ item.title }}
              </a>
            </div>
          </div>

          <div class="userInfo">
            <el-dropdown trigger="hover">
              <div class="el-dropdown-link">
                <p class="login" v-if="!userInfo" @click="handleLogin()">Login</p>
                <img class="loginAvatar" v-if="userInfo && userInfo.avatar" :src="userInfo.avatar" alt="" />
                <img class="loginAvatar" v-if="userInfo && !userInfo.avatar" src="../../imgs/user/s-avatar.png" alt="" />
              </div>
              <el-dropdown-menu class="show-dropdown-menu" slot="dropdown" v-if="userInfo">
                <el-dropdown-item class="ainfo myinfo">
                  <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" @click="goPath('user1')" />
                  <img v-else src="../../imgs/user/s-avatar.png" alt="" @click="goPath('user1')" />
                  <div>
                    <h4 @click="goPath('user1')">{{ userInfo.nickname }}</h4>
                    <p>Community Member</p>
                  </div>
                </el-dropdown-item>
                <!-- <a style="text-decoration: none" @click="goPath('blog1')">
                  <el-dropdown-item class="myinfo">My Blog</el-dropdown-item>
                </a> -->
                <a style="text-decoration: none" @click="goPath('user1')">
                  <el-dropdown-item class="myinfo">Personal Center</el-dropdown-item>
                </a>
                <a style="text-decoration: none" @click="goPath('settings')">
                  <el-dropdown-item class="myinfo">Account Settings</el-dropdown-item>
                </a>
                <!-- <a style="text-decoration: none" @click="goPath('im')">
                  <el-dropdown-item class="myinfo">私信</el-dropdown-item>
                </a> -->
                <!-- <a style="text-decoration: none" @click="openUserInfoDrawer">
                  <el-dropdown-item class="myinfo">My Collect</el-dropdown-item>
                </a>
                <a style="text-decoration: none" @click="openUserInfoDrawer">
                  <el-dropdown-item class="myinfo"
                    >My Followers</el-dropdown-item
                  >
                </a> -->
                <a style="text-decoration: none" @click="logout">
                  <el-dropdown-item class="logout">
                    <p>Log out</p>
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>

              <!-- class="show-dropdown-menu" -->
              <el-dropdown-menu slot="dropdown" v-else>
                <!-- <div class="loginTip" style="padding: 10px; font-size: 0.9rem">
                  <el-button
                    @click="handleLogin()"
                    style="margin: 0 auto; display: block; margin-top: 10px"
                    type="success"
                    size="small"
                    >Sign in</el-button
                  >
                </div> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- <div v-if="userInfo" class="noticeBtn">
            <el-dropdown trigger="hover">
              <div class="el-dropdown-link">
                <i class="el-icon-bell"></i>
                <span v-if="topBageShow()" class="notice-bage topBage"></span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <span
                  v-for="(item, index) in noticeList"
                  :key="index"
                  @click="handleClickNotice(index)"
                >
                  <el-dropdown-item>
                    {{ item }}
                    <span v-if="validata(index)" class="notice-bage"></span>
                  </el-dropdown-item>
                </span>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </div>
      </div>

      <div class="menu-box" v-if="menuFlag">
        <el-row>
          <el-col :span="24">
            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
              background-color="#fff" text-color="#121212">
              <el-menu-item>
                <span @click="goPath('/')">Home</span>
              </el-menu-item>
              <el-submenu index="1">
                <template slot="title">
                  <span> News&Events</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">
                    <span @click="goPath('news')">News</span>
                  </el-menu-item>
                  <el-menu-item index="1-2">
                    <span @click="goPath('event')">Events</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item>
                <span @click="goPath('resources')">Resources</span>
              </el-menu-item>
              <el-menu-item>
                <span @click="goPath('blog-home')">Blogs</span>
              </el-menu-item>
              <el-submenu index="2">
                <template slot="title">
                  <span> History</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="2-1">
                    <span @click="goPath('history1')">Oral History</span>
                  </el-menu-item>
                  <el-menu-item index="2-2">
                    <span @click="goPath('history2')">Timeline</span>
                  </el-menu-item>
                  <el-menu-item index="2-3">
                    <span @click="goPath('history3')">People</span>
                  </el-menu-item>
                  <el-menu-item index="2-4">
                    <span @click="goPath('history4')"> Digital Archives</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item>
                <span @click="goPath('')">Wiki</span>
              </el-menu-item>
              <el-submenu index="3">
                <template slot="title">
                  <span> About</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="3-1">
                    <span @click="goPath('about1')"> What is GPCN？</span>
                  </el-menu-item>
                  <el-menu-item index="3-2">
                    <span @click="goPath('about2')">Membership</span>
                  </el-menu-item>
                  <el-menu-item index="3-3">
                    <span @click="goPath('about3')">Opportunities</span>
                  </el-menu-item>
                  <el-menu-item index="3-4">
                    <span @click="goPath('partners')">Partners</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </div>

      <div class="box2 small-hide">
        <div class="left">
          <div class="one" :class="path == '/' ? 'active' : ''">
            <router-link :to="'/'"> Home </router-link>
          </div>
          <div class="one">
            <el-dropdown class="dropdown">
              <span class="el-dropdown-link" :class="path == '/event' ? 'active' : path == '/news' ? 'active' : ''">
                News&Events<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="home-dropdown-item">
                  <!-- <img src="../../imgs/index/h2.png" alt="" /> -->
                  <router-link :to="'news'" :class="path == '/news' ? 'active' : ''"> News </router-link>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <!-- <img src="../../imgs/index/h1.png" alt="" /> -->
                  <router-link :to="'event'" :class="path == '/event' ? 'active' : ''"> Events </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="one">
            <router-link :to="'resources'" :class="path == '/resources' ? 'active' : ''"> Resources </router-link>
          </div>
          <div class="one" :class="path == '/blog-home' ? 'active' : ''">
            <router-link :to="'blog-home'"> Blogs </router-link>
          </div>
          <div class="one">
            <el-dropdown>
              <span class="el-dropdown-link"> History<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="home-dropdown-item">
                  <router-link :to="'history1'" :class="path == '/history1' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h3.png" alt="" /> -->
                    Oral History
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item class="home-dropdown-item">
                  <router-link :to="'history2'" :class="path == '/history2' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h4.png" alt="" /> -->
                    Timeline
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item class="home-dropdown-item">
                  <router-link :to="'history3'" :class="path == '/history3' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h5.png" alt="" /> -->
                    People
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item class="home-dropdown-item">
                  <router-link :to="'history4'" :class="path == '/history4' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h6.png" alt="" /> -->
                    Digital Archives
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="one">Wiki</div>
          <div class="one">
            <el-dropdown>
              <span class="el-dropdown-link"> About<i class="el-icon-arrow-down el-icon--right"></i> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="home-dropdown-item">
                  <!-- <img src="../../imgs/index/h7.png" alt="" /> -->
                  <router-link :to="'about1'" :class="path == '/about1' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h3.png" alt="" /> -->
                    What is GPCN？
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <!-- <img src="../../imgs/index/h8.png" alt="" /> -->
                  <router-link :to="'about2'" :class="path == '/about2' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h3.png" alt="" /> -->
                    Membership
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <!-- <img src="../../imgs/index/h8.png" alt="" /> -->
                  <router-link :to="'about3'" :class="path == '/about3' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h3.png" alt="" /> -->
                    Opportunities
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <router-link :to="'partners'" :class="path == '/partners' ? 'active' : ''">
                    <!-- <img src="../../imgs/index/h9.png" alt="" /> -->
                    Partners
                  </router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="right">
          <div @click="clickRss" v-if="path == '/news' || path == '/blog-home'">
            <!-- Rss feed -->
            <img src="../../imgs/index/top-2.png" alt="" />
          </div>
          <!-- <div>Rss feed</div> -->
          <div class="two">
            <!-- Subscribe to notifications -->
            <!-- <img src="../../imgs/index/top-2.png" alt="" /> -->

            <el-dropdown class="dropdown">
              <span class="el-dropdown-link">
                <img src="../../imgs/index/h10.png" alt="" />

                <p class="number" v-if="numObj.comment + numObj.watch + numObj.private > 0">
                  {{ numObj.comment + numObj.watch + numObj.private > 99 ? 99 : numObj.comment + numObj.watch +
                    numObj.private || 0 }}
                </p>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="home-dropdown-item">
                  <div class="number-box" @click="goPath('user5')">
                    <p class="l">Comments</p>
                    <p class="r" v-if="numObj.comment > 0">
                      {{ numObj.comment > 99 ? 99 : numObj.comment || 0 }}
                    </p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <div class="number-box" @click="goPath('user2')">
                    <p class="l">Followers</p>
                    <p class="r" v-if="numObj.watch > 0">
                      {{ numObj.watch > 99 ? 99 : numObj.watch || 0 }}
                    </p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item class="home-dropdown-item">
                  <div class="number-box" @click="goPath('user5?type=3')">
                    <p class="l">Private messages</p>
                    <p class="r" v-if="numObj.private > 0">
                      {{ numObj.private > 99 ? 99 : numObj.private || 0 }}
                    </p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </nav>
    <el-dialog :visible.sync="dialogVisiable" class="registerDialog" :show-close="false">
      <div class="changePass-box">
        <p class="btitle">
          Download brand assets
        </p>
        <div class="imgBox">
          <img src="../../imgs/downLoad/downLoad.png">
        </div>
        <div class="btn-box">
          <div class="btn2" @click="dialogVisiable = false">
            <a href="../../assets/paleogeography.zip" download> Download zip
              file</a>

          </div>
        </div>
      </div>
    </el-dialog>
  </header>
</template>

<script>
import { logout } from "@/api";
import { getNewSystemNotice } from "@/api/im.js";
import { getToken, removeToken } from "@/utils/cookieUtil";

export default {
  name: "Header",
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menuFlag: false,

      widthPre: "",
      keywords: null,
      user: this.$store.state.userInfo,
      style: null,
      path: null,
      isMobile: false,
      noneInput: false,
      windowWidth: 0,
      headerClass: "header",
      adminUrl: process.env.VUE_APP_ADMIN_API,
      colors: ["#FE2D46", "#FF6600", "#FAA90E", "#7f7f8c", "#7f7f8c"],
      noticeList: ["系统通知", "评论", "关注", "点赞", "收藏", "私信"],

      numObj: {},
      dialogVisiable: false
    };
  },
  created() {
    this.timers();
  },
  mounted() {
    this.getNumber();

    this.path = this.$route.path;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        this.windowWidth = window.innerWidth; // 宽
      })();
    };
    window.addEventListener("scroll", this.scrollFn, false);
  },

  watch: {
    // 监听页面宽度
    windowWidth(val) {
      console.log("val===", val);
      this.isMobile = val < 1119;
      this.noneInput = val < 1410;
    },
  },

  methods: {
    // 单击鼠标右键
    handleRightClick(event) {
      event.preventDefault()
      // this.dialogVisiable = true
      this.$emit('childEvent', true);
      console.log("11")
    },
    timers() {
      // console.log("==init timers");
      const timer = setInterval(() => {
        //需要做的事情
        console.log(11111);
        this.getNumber();
      }, 120000);
      this.$on("hook:beforeDestroy", () => {
        clearInterval(timer);
        this.timer = null;
      });
    },
    // 获取铃铛数字
    getNumber() {
      if (this.$store.state.userInfo) {
        if (getToken()) {
          getNewSystemNotice().then((res) => {
            this.numObj = res.data || {};
          });
        }
      }
    },
    clickRss() {
      let url = "";
      if (this.path == "/news") {
        url = process.env.VUE_APP_BASE_API + "v1/rss/getRss/news";
      } else if (this.path == "/blog-home") {
        url = process.env.VUE_APP_BASE_API + "v1/rss/getRss/blog";
      }

      this.$copyText(url).then(
        (e) => {
          this.$message({
            showClose: true,
            message: "Copy successfully",
            type: "success",
          });
          // console.log("复制成功：", e);
        },
        (e) => {
          this.$message({
            showClose: true,
            message: "Copy failed",
            type: "error",
          });
          // console.log("复制失败：", e);
        }
      );
    },
    goPath(path) {
      if (!path) {
        return;
      }
      location.href = path;
    },
    handleClickNotice(index) {
      if (!this.user) {
        this.$store.commit("setLoginFlag", true);
        return;
      }
      if (index == this.noticeList.length - 1) {
        this.$router.push({ path: "/im" });
        return;
      }
      this.$router.push({ path: "notice", query: { type: index } });
    },
    topBageShow() {
      return this.$store.state.systemNotcie.system > 0 || this.$store.state.systemNotcie.comment > 0 || this.$store.state.systemNotcie.watch > 0 || this.$store.state.systemNotcie.like > 0 || this.$store.state.systemNotcie.collect > 0 || this.$store.state.systemNotcie.message > 0 || this.$store.state.systemNotcie.private > 0;
    },
    validata(index) {
      switch (index) {
        case 0:
          return this.$store.state.systemNotcie.system > 0;
        case 1:
          return this.$store.state.systemNotcie.comment > 0;
        case 2:
          return this.$store.state.systemNotcie.watch > 0;
        case 3:
          return this.$store.state.systemNotcie.like > 0;
        case 4:
          return this.$store.state.systemNotcie.collect > 0;
        case 5:
          return this.$store.state.systemNotcie.private > 0;
        default:
          return false;
      }
    },
    //Enter事件
    handkeyEnter(event) {
      if (event.keyCode == 13) {
        this.search();
      }
    },
    addArticle() {
      if (!this.userInfo) {
        this.handleLogin();
        return;
      }
      this.$store.state.articleDrawer.flag = true;
      this.$store.state.articleDrawer.id = null;
    },
    openUserInfoDrawer() {
      this.$store.state.userInfoDrawer.flag = true;
      this.$store.state.userInfoDrawer.name = null;
    },
    handleArticle(item) {
      // this.$router.push({ path: "/articleInfo", query: { articleId: id } });
      if (item.typec == "news") {
        location.href = "news-detail?articleId=" + item.id;
      } else if (item.typec == "blog") {
        location.href = "blog-detail?articleId=" + item.id;
      } else if (item.typec == "event") {
        location.href = "event-detail?articleId=" + item.id;
      } else if (item.typec == "history_oral") {
        location.href = "history1-detail?id=" + item.id;
      } else if (item.typec == "history_digital") {
        location.href = "history4-detail?articleId=" + item.id;
      }
    },
    focus() {
      this.style = "transform:translate3d(0, 0, 0);opacity:1;visibility:visible;border: 1px solid var(--background-color)";
    },
    blur() {
      this.style = "opacity:0;visibility:hidden";
    },
    search() {
      if (!this.keywords) {
        return;
      }
      this.$router.push({
        path: "/search",
        query: { keyword: this.keywords.trim() },
      });
    },
    scrollFn() {
      // if (this.$route.paht != '/message') {
      //     // 页面滚动距顶部距离
      //     let scrollTop =
      //         window.pageYOffset ||
      //         document.documentElement.scrollTop ||
      //         document.body.scrollTop;
      //     let scroll = scrollTop - this.i;
      //     this.i = scrollTop;

      //     // 鼠标向上滚动
      //     if (scroll <script 0) {
      //         this.headerClass = "header slideDown"
      //     } else {
      //         this.headerClass = "header slideUp"
      //     }
      // }
      let page = document.body.scrollHeight;
      let client = document.documentElement.clientHeight;
      let comend = page - client;
      let scrTop = document.documentElement.scrollTop;
      // console.log()
      this.widthPre = Math.round((scrTop / comend) * 10000) / 100;
    },
    handleLogin() {
      this.$store.commit("setLoginFlag", true); // 存储到vuex
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.path === "/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          removeToken();
          this.$store.commit("setUserInfo", null);
          // location.reload();
          this.$message.success("Successful deregistration");
          location.href = "/";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDrawer() {
      this.$store.commit("setDrawer", true);
    },
    openSearchDrawer() {
      this.$store.commit("setSearchDrawer", true);
    },
  },
};
</script>
<style scoped lang="scss">
.changePass-box {
  width: 100%;
  z-index: 999 !important;

  .btitle {
    font-size: 20px;
    font-weight: bold;
    color: #121212;
    line-height: 30px;
    margin-bottom: 25px;
  }

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .btn-box {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 184px;
      height: 46px;

      font-size: 16px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      line-height: 46px;
      cursor: pointer;
    }

    .btn2 {
      background: #bb490a;
    }
  }
}


/deep/ .el-dropdown-menu__item {
  // width: 71px;
  // height: 25px;
  // font-size: 18px;
  // // font-family: Poppins-Medium, Poppins;
  // font-weight: 500;
  // color: #000000;
  // line-height: 27px;

  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 25px;
  padding-left: 30px;

  margin: 10px 0;
}

.ainfo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  img {
    height: 42px;
    margin-right: 10px;
  }

  h4 {
    // width: 98px;
    height: 25px;
    // font-size: 20px;
    // // font-family: Lora-Bold, Lora;
    // font-weight: 500;
    // color: #000000;
    // line-height: 25px;
    margin: 0;

    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
  }

  p {
    // width: 90px;
    height: 20px;
    // font-size: 14px;
    // // font-family: Poppins-Regular, Poppins;
    // font-weight: 400;
    // color: #666666;
    // line-height: 21px;

    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;

    margin: 0;
  }
}

.logout {
  text-align: center;
  padding-top: 20px;

  p {
    padding: 5px 20px;
    background: #bb490a;
    color: #fff;
    width: fit-content;
    margin: 0 auto;
  }
}

.logout:hover {
  background: none;
}

.topicon {
  // height: 40px;
  display: flex;
  align-items: center;

  img {
    margin-bottom: 2px;
    margin-right: 3px;
  }
}

.notice-bage {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}

.topBage {
  vertical-align: 15px;
}

#pre {
  position: fixed;
  top: 0;
  height: 2px;
  background-image: -webkit-linear-gradient(0deg, #3ca5f6 0, #a86af9 100%);
}

@media screen and (max-width: 1118px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    // line-height: 60px;
    z-index: 99;
    background-color: var(--header-back-color);

    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      // overflow: hidden;
      max-width: 1500px;

      .logo {
        font-size: 22px;
        font-weight: 700;
        width: 100%;
        position: relative;
        display: flex;

        .logoBox {
          height: 60px;
          display: flex;
          align-items: center;
          margin-left: 20px;
          position: relative;
          margin: 0 auto;
        }

        .img {
          width: 80px;
          height: 40px;
          vertical-align: middle;
          margin-top: -6px;
        }

        a {
          margin-left: 10px;
          color: var(--theme-color);
        }
      }

      .starlist {
        display: none;
      }

      // .searchBox,
      // .articleBtn,
      // .starlist,
      // .noticeBtn,
      // .userInfo {
      //   display: none;
      // }
      .searchBox,
      .articleBtn,
      .starlist,
      .noticeBtn {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1119px) {

  /*针对宽度小于等于767px的屏幕设备，定义如下样式*/
  .slideDown {
    transition: transform 0.5s ease-out;
    transform: translateY(0);
  }

  .slideUp {
    transition: transform 0.5s ease-out;
    transform: translateY(-100px);
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    // line-height: 60px;
    z-index: 99;
    background-color: var(--header-back-color);
    backdrop-filter: blur(4px);

    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      // overflow: hidden;
      max-width: 1500px;

      .logo {
        float: left;
        font-size: 22px;
        font-weight: 700;

        .img {
          width: 80px;
          height: 40px;
          vertical-align: middle;
          margin-top: -6px;
        }

        a {
          margin-left: 10px;
          color: var(--theme-color);
        }
      }

      .starlist {
        display: block;
        float: left;
        margin-left: 50px;
        overflow: hidden;
        max-width: 1500px;

        .el-dropdown-link {
          cursor: pointer;
          z-index: 9999999;
        }

        li {
          float: left;
          display: block;
          font-size: 14px;
          padding: 0 15px;
          position: relative;

          i {
            font-weight: 700;
          }

          .active {
            color: var(--theme-color);
          }

          a {
            &:hover {
              color: var(--theme-color);
            }
          }
        }
      }

      .searchBox {
        position: absolute;
        right: 240px;
        top: 0;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;

        .search_open {
          width: 200px;
          display: inline-block;
          padding-left: 10px;
          height: 60px;

          /deep/ .el-input__inner {
            border: 1px solid var(--input-boder);
            background: var(--background-color);
            width: 210px;
            padding-left: 8px;
            height: 35px;
            border-radius: 20px;
            transition: all 2s;
          }
        }

        .search_ico {
          position: absolute;
          right: 5px;
          top: 0;
          padding: 0;
          margin: 0;
          line-height: 60px;
          cursor: pointer;
          text-align: right;

          .iconfont {
            font-size: 20px;
            font-weight: 700;
          }
        }

        .hot_search_main {
          background-color: var(--background-color);
          border-radius: 2px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          position: absolute;
          z-index: 99;
          top: 60px;
          left: 10px;
          right: -10px;
          opacity: 0;
          visibility: hidden;
          transition: visibility 0.35s, opacity 0.35s, transform 0.35s;
          transform: translate3d(0, 15px, 0);

          a {
            height: 30px;
            text-decoration: none;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            /* border-bottom: 1px solid #f2f6fc; */
            line-height: 30px;
            padding: 4px 5px;
            color: #606266;
            font-size: 12px;

            span {
              width: 20px;
              height: 20px;
              display: inline-block;
              line-height: 21px;
              text-align: center;
              border-radius: 3px;
              color: var(--background-color);
            }

            &:first-child {
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }

            &:last-child {
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }

            &:hover {
              background-color: rgb(235, 238, 245);
            }
          }
        }
      }

      .articleBtn {
        position: absolute;
        right: 230px;
        top: 0;
      }

      .noticeBtn {
        position: absolute;
        right: 180px;
        top: 0;

        i {
          font-size: 1.5rem;
          color: var(--article-color);
        }

        /deep/ .el-dropdown {
          width: 35px;
          height: 35px;
          right: -10px;
          top: 5px;
        }
      }

      .userInfo {
        position: absolute;
        right: 120px;
        top: 0;

        /deep/ .el-dropdown {
          width: 35px;
          height: 35px;
          right: 0;
          top: 10px;
        }

        img {
          // width: 40px;
          // height: 40px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid var(--border-line);
          position: absolute;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  z-index: 999;
}

.top-box {
  max-width: 1250px !important;
  width: 100%;
  margin: 0 auto;

  .box1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 14px;
    padding-left: 20px;
    margin-bottom: 14px;
    padding-bottom: 14px;
    // border-bottom: 1px solid red;

    .left {
      img {
        width: 170px;
        height: 40px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .searchBox {
        position: relative !important;
        line-height: 32px;
        right: 0;
        margin-right: 20px;

        .search_open {
          width: 300px;
          height: 32px !important;
          padding: 0;
        }

        /deep/.el-input__inner {
          height: 32px !important;
          border: 1px solid #e5e5e5;

          width: 300px;
          line-height: 32px;

          font-size: 14px;
          font-weight: 500;
          color: #000000;
          line-height: 24px;
          padding-left: 10px;
          border-radius: 0 !important;
        }

        .search-img {
          width: 19px;
          height: 19px;

          position: absolute;
          right: 5px;
          top: 6px;
        }

        .search_ico {
          width: 92px;
          height: 32px;
          background: #bb490a;

          font-size: 16px;

          font-weight: 500;
          color: #ffffff;
          line-height: 32px;
          text-align: center;
          right: 0;
        }
      }

      .userInfo {
        position: relative;
        right: 0;
        top: 0;
        // width: 32px;
        height: 32px;
        cursor: pointer;

        /deep/.el-dropdown {
          top: 0;
          width: 50px;
          height: 32px;
        }

        .login {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          line-height: 32px;
          margin: 0;

          cursor: pointer;
        }
      }
    }
  }

  .box2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;

    .left {
      display: flex;
      align-items: center;

      .one {
        margin-right: 50px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }

      .one a {
        color: #000000;
      }

      .one:hover,
      .active a,
      .active,
      .one a:hover {
        color: #bb490a !important;
      }

      /deep/.el-dropdown {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      padding-right: 20px;

      div {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 18px;
        // text-decoration: underline;
        margin-bottom: 0;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }
      }

      .two {
        display: flex;
        align-items: center;
        margin-left: 36px;

        position: relative;

        img {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }

        .number {
          font-size: 10px;
          font-weight: 400;
          color: #ffffff;

          background: #db1111;

          text-align: center;
          line-height: 16px;

          width: 16px;
          height: 16px;
          border-radius: 100%;

          position: absolute;
          right: -5px;
          top: -5px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.home-dropdown-item {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #000000;

  a {
    color: #000000;
  }

  .active {
    color: #bb490a !important;
  }

  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.home-dropdown-item:hover,
.home-dropdown-item a:hover,
.myinfo:hover {
  background-color: #f5f5f5 !important;
  color: #bb490a !important;
  text-decoration: none;
}

.myinfo {
  img {
    border-radius: 100%;
    width: 42px;
    height: 42px;
  }
}

.show-dropdown-menu {
  z-index: 19002 !important;
  width: 250px;
}

.number-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;

  .l {
    // padding-right: 60px;

    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
  }

  .r {
    width: 16px;
    height: 16px;
    background: #db1111;

    font-size: 10px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    border-radius: 100%;
  }
}
</style>

<!-- 适配 -->
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .registerDialog {
    /deep/.el-dialog {
      width: 95%;
    }
  }

  .small-wrap {
    flex-wrap: wrap;
  }

  .small-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .menu {
      width: 32px !important;
      cursor: pointer;
      height: 32px;
    }
  }

  .userInfo {

    // /deep/.el-dropdown {
    //   width: 32px !important;
    // }
    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
  }

  .menu-box {
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.11);
  }

  /deep/.el-submenu__title:hover {
    background-color: #f2f2f2 !important;
  }
}

@media screen and (min-width: 600px) {
  .small-wrap {
    flex-wrap: wrap;
  }
}
</style>
<style lang="scss" scoped>
.loginAvatar {
  overflow: hidden;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
</style>
