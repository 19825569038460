<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { report } from "@/api";
import { getSkin, setSkin } from "@/utils/cookieUtil";
export default {
  name: "App",
  created() {
    report();
  },
  beforeCreate() {
    if (getSkin() == null) {
      setSkin("shallow");
    } else {
      setSkin(getSkin());
    }
  },
  mounted() {
    this.$setSkin();
  },
};
</script>
<style>
html {
  scroll-padding-top: 190px;
  scroll-behavior: smooth;
}
</style>
<style lang="scss" scoped>
#app {
  // background: var(--body-color);
  background: #fff;
}

/deep/ .el-card__body {
  padding: 0;
}

/deep/ .el-card {
  border: 0;
}

@media screen and (max-width: 1118px) {
  .dialog {
    width: 100%;

    /deep/ .el-dialog {
      width: 100% !important;
    }
  }
}

@media screen and (min-width: 1119px) {
  .main-container {
    display: flex;
    justify-content: center;

    .main {
      width: 100% !important;
      margin-top: 149px;
    }
  }
}
</style>

<!-- 通用 -->
<style lang="scss">
.main-container {
  display: flex;
  justify-content: center;

  .main {
    width: 100%;
    margin-top: 129px;
  }
}

p {
  margin: 0;
}

@font-face {
  font-family: "open";
  src: url("../src/imgs/open-sans-m.woff2") format("truetype");
}

p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
button {
  // font-family: Roboto, serif;
  // font-family: "Poppins", sans-serif !important;
  font-family: "open", sans-serif !important;
}

.hover-img {
  overflow: hidden;
}
.hover-img img {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.hover-img img:hover {
  transform: scale(1.1);
}

.image-sm:hover {
  opacity: 0.84;
}
.hover-title {
  cursor: pointer;
}
.hover-title:hover {
  color: #bb490a !important;
}

@font-face {
  font-family: "nyt-cheltenham";
  src: url("../src/cheltenham-normal-400.4bffe18a586b3b49c9c6d44e382be438.ttf") format("truetype");
}
@font-face {
  font-family: "nyt-imperial";
  src: url("../src/imperial-normal-400.9d83d7e0006b668c4caeabf1e42c7cdc.ttf") format("truetype");
}
.family-h {
  font-family: nyt-cheltenham, georgia, "times new roman", times, serif !important;
}
.family-desc {
  font-family: nyt-imperial !important;
}
.color-21 {
  color: #121212 !important;
  font-weight: 700 !important;
}
.color-5a {
  color: #5a5a5a !important;
  font-weight: 500 !important;
}

// 内容 上下对齐
.con-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>

<!-- 分享 -->
<style lang="scss">
.my-share-box {
  .el-button {
    padding: 0;
    border: none;
  }
  .el-button:hover {
    background: none;
    color: #bb490a;
  }
}

.my-share-box-event {
  .el-button {
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    color: #121212;
    line-height: 16px;
  }
}
.shareBox {
  padding: 16px;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    font-size: 18px;
    font-weight: 400;
    color: #121212;
    line-height: 27px;

    cursor: pointer;
  }
  .item:nth-child(5) {
    margin-bottom: 0;
  }
}

// img {
//   background: url(@/imgs/user/kong1.png) no-repeat;
//   background-size: cover;
// }
.card__post-list .card__post__content .card__post__title h6 {
  text-transform: none !important;
}
</style>

<!-- 适配通用 -->
<style lang="scss">
@media screen and (max-width: 600px) {
  .small-hide {
    display: none !important;
  }
  /deep/.small-hide {
    display: none !important;
  }

  .small-column {
    flex-direction: column !important;
  }

  .main-container {
    .main {
      width: 100%;
      overflow: hidden;
      margin-top: 89px !important;
    }
  }

  .small-mt-0,
  #small-mt-0 {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 600px) {
  .big-hide {
    display: none !important;
  }
}
</style>
